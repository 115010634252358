const FACEBOOK_SCRIPT_URL =
  "https://connect.facebook.net/pl_PL/sdk.js#xfbml=1&version=v8.0";

let injectSocialScriptsCounter = 0;

const injectPreloadLink = (url) => {
  try {
    const link = document.createElement("link");
    link.rel = "preload";
    link.as = "script";
    link.href = url;
    document.head.appendChild(link);
  } catch (error) {
    console.error(error);
  }
};

const injectFacebookScript = (facebookOptions) => {
  try {
    if (
      facebookOptions &&
      facebookOptions.containerSelector &&
      facebookOptions.containerSelector.length > 0 &&
      document.querySelector(facebookOptions.containerSelector) &&
      facebookOptions.profile &&
      facebookOptions.profile.length > 0
    ) {
      const facebookRoot = document.createElement("div");
      facebookRoot.id = "fb-root";
      const gatsbyContainer = document.getElementById("___gatsby");
      if (gatsbyContainer) {
        gatsbyContainer.prepend(facebookRoot);
      }
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.setAttribute("async", "async");
      script.setAttribute("defer", "defer");
      script.setAttribute("src", FACEBOOK_SCRIPT_URL);
      injectPreloadLink(FACEBOOK_SCRIPT_URL);
      document.head.appendChild(script);
      injectSocialScriptsCounter++;
    }
  } catch (error) {
    console.error(error);
  }
};

const injectFacebookContainer = (facebookOptions) => {
  try {
    if (
      facebookOptions &&
      facebookOptions.containerSelector &&
      facebookOptions.containerSelector.length > 0 &&
      document.querySelector(facebookOptions.containerSelector) &&
      facebookOptions.profile &&
      facebookOptions.profile.length > 0
    ) {
      const facebookContainer = document.querySelector(
        facebookOptions.containerSelector
      );
      const facebookTimeline = `<div class="fb-page" data-href="https://www.facebook.com/${
        facebookOptions.profile
      }/" data-tabs="${
        facebookOptions.tabs && facebookOptions.tabs.length > 0
          ? facebookOptions.tabs
          : "timeline, events, messages"
      }" data-width="${
        facebookOptions.width &&
        typeof facebookOptions.width === "number" &&
        facebookOptions.width >= 180 &&
        facebookOptions.width <= 500
          ? facebookOptions.width
          : ""
      }" data-height="${
        facebookOptions.height &&
        typeof facebookOptions.height === "number" &&
        facebookOptions.height >= 70
          ? facebookOptions.height
          : ""
      }" data-small-header="${
        facebookOptions.smallHeader === true ? "true" : "false"
      }" data-adapt-container-width="${
        facebookOptions.adaptContainerWidth === true ? "true" : "false"
      }" data-hide-cover="${
        facebookOptions.hideCover === true ? "true" : "false"
      }" data-show-facepile="${
        facebookOptions.showFacepile === true ? "true" : "false"
      }"><blockquote cite="https://www.facebook.com/${
        facebookOptions.profile
      }/" class="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/${
        facebookOptions.profile
      }/" target="_blank" rel="noopenernoopener noreferrer nofollow" title="${
        facebookOptions.profile
      }'s Facebook Page">Follow ${
        facebookOptions.profile
      }</a></blockquote></div>`;
      facebookContainer.innerHTML = facebookTimeline;
      if (window.FB && window.FB.init) {
        window.FB.init({
          status: true,
          xfbml: true,
          version: "v8.0",
        });
      }
    }
  } catch (error) {
    console.error(error);
  }
};

export const injectSocialScripts = (pluginOptions) => {
  console.log(pluginOptions);
  setTimeout(
    () => {
      if (injectSocialScriptsCounter === 0) {
        if (pluginOptions && pluginOptions.facebook) {
          injectFacebookContainer(pluginOptions.facebook);
          injectFacebookScript(pluginOptions.facebook);
        }
      } else {
        if (pluginOptions && pluginOptions.facebook) {
          injectFacebookContainer(pluginOptions.facebook);
        }
      }
    },
    pluginOptions.delayTimer &&
      typeof pluginOptions.delayTimer === "number" &&
      pluginOptions.delayTimer > 0
      ? pluginOptions.delayTimer
      : 1000
  );
};

export default injectSocialScripts;
