import "./src/styles/style.scss";
import "react-image-lightbox/style.css";
import {injectSocialScripts} from "./src/utils/socials"

// Import Swiper styles
import "swiper/swiper.scss";
import pagination from "swiper/components/pagination/pagination.scss";
import navigation from "swiper/components/navigation/navigation.scss";
const imported = [pagination, navigation]
 
export const onRouteUpdate = () => {
	injectSocialScripts({
        facebook: {
            profile: 'nieladakawa',
            containerSelector: '.facebook-widget',
            width: 500,
            height: 500
        },
        delayTimer: 3000
    });
};