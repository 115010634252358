// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-ekspresy-js": () => import("./../src/pages/ekspresy.js" /* webpackChunkName: "component---src-pages-ekspresy-js" */),
  "component---src-pages-herbaty-js": () => import("./../src/pages/herbaty.js" /* webpackChunkName: "component---src-pages-herbaty-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kawy-js": () => import("./../src/pages/kawy.js" /* webpackChunkName: "component---src-pages-kawy-js" */),
  "component---src-pages-kontakt-js": () => import("./../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-serwis-js": () => import("./../src/pages/serwis.js" /* webpackChunkName: "component---src-pages-serwis-js" */),
  "component---src-pages-szkolenia-js": () => import("./../src/pages/szkolenia.js" /* webpackChunkName: "component---src-pages-szkolenia-js" */)
}

